import { writable, derived } from 'svelte/store';
import { browser } from '$app/environment';

function createToken() {
	const { subscribe, set } = writable({
		token:
			browser &&
			((localStorage.getItem('token') !== 'undefined' && localStorage.getItem('token')) || ''),
		age:
			browser && ((localStorage.getItem('age') !== 'undefined' && localStorage.getItem('age')) || 0)
	});

	return {
		subscribe,
		set: (newValue) => set(newValue)
	};
}
export const TOKEN = createToken();

function checkTokenStore() {
	const { subscribe, set } = writable(false);

	return {
		subscribe,
		set: (newValue) => set(newValue)
	};
}
export const TOKEN_CHECK = checkTokenStore();
// export const TOKEN = writable(
// 	browser &&
// 		((localStorage.getItem('token') !== 'undefined' && localStorage.getItem('token')) || '')
// );

export const USER_DETAILS = writable(
	browser &&
		((localStorage.getItem('USER_DETAILS') !== 'undefined' &&
			JSON.parse(localStorage.getItem('USER_DETAILS'))) ||
			null)
);
USER_DETAILS.subscribe((value) => browser && (localStorage.USER_DETAILS = JSON.stringify(value)));

export const ERROR_MESSAGE = writable('');
export const UNIVERSAL_SUCCESS_MESSAGE = writable('');
export const UNIVERSAL_ERROR_MESSAGE = writable('');

export const TERM = writable(
	browser &&
		((localStorage.getItem('TERM') !== 'undefined' && localStorage.getItem('TERM')) || null)
);
TERM.subscribe((value) => browser && (localStorage.TERM = value));

export const TERM_DETAILS = writable(null);

export const CLASSROOMS = writable(
	browser &&
		((localStorage.getItem('CLASSROOMS') !== 'undefined' &&
			JSON.parse(localStorage.getItem('CLASSROOMS'))) ||
			[])
);
CLASSROOMS.subscribe((value) => browser && (localStorage.CLASSROOMS = JSON.stringify(value)));

export const CLASSROOMS_IDS = writable(
	browser &&
		((localStorage.getItem('CLASSROOMS_IDS') !== 'undefined' &&
			JSON.parse(localStorage.getItem('CLASSROOMS_IDS'))) ||
			[])
);
CLASSROOMS_IDS.subscribe(
	(value) => browser && (localStorage.CLASSROOMS_IDS = JSON.stringify(value))
);

export const ADD_CLASSROOM = writable(false);
export const CURRENT_CLASSROOMS_LIST = writable([]);
export const ADD_CLASSROOM_SCHEDULE = writable(false);

export const ADD_TEACHER = writable(false);
export const CURRENT_TEACHERS_LIST = writable([]);

export const ADD_SUBJECT = writable(false);
export const CURRENT_SUBJECTS = writable([]);
export const CURRENT_SUBJECT = writable('');

export const SHOW_ADD_TERM = writable(false);
export const SHOW_EDIT_TERM = writable(false);
export const SHOW_GRADING_SCHEME = writable(false);

export const ADD_STUDENT = writable(false);
export const ADD_STUDENT_SCHEDULE = writable(false);
export const STUDENTS = writable([]);
export const STUDENTS_TOTAL = derived(STUDENTS, ($STUDENTS) => $STUDENTS && $STUDENTS.length);
export const STUDENTS_MALE = derived(
	STUDENTS,
	($STUDENTS) => $STUDENTS && $STUDENTS.filter((student) => student.sex.toLowerCase() === 'male')
);
export const STUDENTS_FEMALE = derived(
	STUDENTS,
	($STUDENTS) =>
		$STUDENTS && $STUDENTS.filter((student) => student.sex.toLowerCase() === 'female').length
);

export const TITLE = writable('');
export const DESCRIPTION = writable('');
export const TERMS_ALL = writable([]);
export const FEES_ITEMS = writable([]);
export const CLASSROOMS_ALL = writable([]);
export const CLASSARMS_ALL = writable([]);
export const CLASSECTION_ALL = writable([]);

export const NETWORK_ERROR = writable('');

export const FEE_SCHEDULE_TAB = writable(0);

export const LOGO = writable(null);

export const FONT_CHECKER = writable(false);

export const SEARCH_MODE = writable(false);

export const TOAST_N = writable(null);

export const HAS_USER_ACCESS = writable(false);

// export const CURRENT_URL = writable('');
export const CURRENT_URL = writable(
	browser &&
		((localStorage.getItem('CURRENT_URL') !== 'undefined' && localStorage.getItem('CURRENT_URL')) ||
			'')
);
CURRENT_URL.subscribe((value) => browser && (localStorage.CURRENT_URL = value));

export const SHOW_USER_DELETE = writable(false);
export const SHOW_MODAL = writable(false);
export const SHOW_STAFF_MODAL = writable(false);

export const SCHOOL_NAME = writable(
	browser &&
		((localStorage.getItem('SCHOOL_NAME') !== 'undefined' && localStorage.getItem('SCHOOL_NAME')) ||
			'')
);
SCHOOL_NAME.subscribe((value) => browser && (localStorage.SCHOOL_NAME = value));

export const ADD_STAFF = writable(false);

export const SELECTED_MOBILE_SUBJECT = writable(
	browser &&
		((localStorage.getItem('SELECTED_MOBILE_SUBJECT') !== 'undefined' &&
			localStorage.getItem('SELECTED_MOBILE_SUBJECT')) ||
			'')
);
SELECTED_MOBILE_SUBJECT.subscribe(
	(value) => browser && (localStorage.SELECTED_MOBILE_SUBJECT = value)
);

export const SELECTED_MOBILE_SUBJECT_COLUMN = writable(
	browser &&
		((localStorage.getItem('SELECTED_MOBILE_SUBJECT_COLUMN') !== 'undefined' &&
			localStorage.getItem('SELECTED_MOBILE_SUBJECT_COLUMN')) ||
			'')
);
SELECTED_MOBILE_SUBJECT_COLUMN.subscribe(
	(value) => browser && (localStorage.SELECTED_MOBILE_SUBJECT_COLUMN = value)
);

export const LOGIN_TYPE = writable(
	browser &&
		((localStorage.getItem('LOGIN_TYPE') !== 'undefined' && localStorage.getItem('LOGIN_TYPE')) ||
			null)
);
LOGIN_TYPE.subscribe((value) => browser && (localStorage.LOGIN_TYPE = value));
